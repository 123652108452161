export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer"])},
        "todoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo List"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
      },
      "ru": {
        "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймер"])},
        "todoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
      }
    }
  })
}
