export enum Colors {
  NONE = 'NONE',
  BG = '#F5F5F5',

  ACCENT_MAIN = '#F6298F',
  ACCENT_LIGHT = '#FEDFEE',
  ACCENT_HOVER = '#C42072',

  GRAY_400 = '#585657',
  GRAY_300 = '#B2B2B2',
  GRAY_200 = '#DCDADB',
  GRAY_100 = '#F5F5F5',
  GRAY_000 = '#FFFFFF',

  SYSTEM_SUCCESS = '#35D070',
  SYSTEM_ERROR = '#ED4A4A',
  SYSTEM_WARNING = '#F7D82C',
  SYSTEM_PLACEHOLDER = 'rgba(#5D5B5C, 0.3)',
  SYSTEM_DISABLED = '#D3D4DB'
}
